/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useFormik} from 'formik'
import SVG from 'react-inlinesvg'
import {getUserByToken, login} from '../core/_requests'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {useAuth} from '../core/Auth'
import {Button, Image} from 'react-bootstrap'

const MobileSchema = Yup.object().shape({
  mobile: Yup.string()
    .min(10, 'Minimum 10 digit required')
    .max(12, 'Maximum 12 digit')
    .required('Mobile Number is required'),
})

const OTPSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
  otp: Yup.string()
    .min(3, 'Minimum 5 digit required')
    .max(5, 'Maximum 5 digit')
    .required('Please enter valid OTP'),
})

const initialValues = {
  mobile: '9998792646',
}

const initialOTPValues = {
  otp: '12345',
  email: 'admin@demo.com',
  password: 'demo',
}

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Login() {
  const [loading, setLoading] = useState(false)
  const {saveAuth, setCurrentUser} = useAuth()

  const [mobileForm, SetMobileForm] = useState()
  const [otpForm, SetOTPForm] = useState(false)

  const Mobileformik = useFormik({
    initialValues,
    validationSchema: MobileSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      SetOTPForm(true)
      setSubmitting(false)
    },
  })
  const OTPformik = useFormik({
    initialValues: initialOTPValues,
    validationSchema: OTPSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        const {data: auth} = await login(values.email, values.password)
        saveAuth(auth)
        const {data: user} = await getUserByToken(auth.api_token)
        setCurrentUser(user)
      } catch (error) {
        console.error(error)
        saveAuth(undefined)
        setStatus('The login details are incorrect')
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  return (
    <div className='loginForm'>
      {otpForm ? (
        <div className='otp'>
          <form
            className='form w-100'
            onSubmit={OTPformik.handleSubmit}
            noValidate
            id='kt_login_signin_form'
          >
            {/* begin::Heading */}
            <div className='text-center mb-11'>
              <div className='symbol symbol-70px symbol-circle mb-5'>
                <div className='symbol-label bg-primary text-inverse-primary fw-bold'>
                  <SVG src={toAbsoluteUrl('/media/ilead/otp.svg')} width={'44px'} fill='white' />
                </div>
              </div>
              <h1 className='text-dark fw-bolder mb-3'>Mobile Verification</h1>
              <p className='text-dark fs-4 mb-3'>
                We have sent you an access code via SMS for mobile number verification
              </p>
            </div>
            {/* begin::Heading */}
            {/* begin::Form group */}
            <div className='fv-row mb-8'>
              <label className='form-label fs-6 fw-bolder text-dark d-none d-md-block'>OTP</label>
              <input
                placeholder='OTP'
                {...OTPformik.getFieldProps('otp')}
                className={clsx(
                  'form-control bg-transparent rounded-pill',
                  {'is-invalid': OTPformik.touched.otp && OTPformik.errors.otp},
                  {
                    'is-valid': OTPformik.touched.otp && !OTPformik.errors.otp,
                  }
                )}
                type='text'
                name='otp'
                autoComplete='off'
              />
              {OTPformik.touched.otp && OTPformik.errors.otp && (
                <div className='fv-plugins-message-container'>
                  <span role='alert'>{OTPformik.errors.otp}</span>
                </div>
              )}
            </div>
            {/* end::Form group */}
            {/* begin::Action */}
            <div className='d-grid mb-10'>
              <button
                type='submit'
                id='kt_sign_in_submit'
                className='btn btn-primary rounded-pill'
                disabled={OTPformik.isSubmitting || !OTPformik.isValid}
              >
                {!loading && <span className='indicator-label'>Continue</span>}
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Please wait...
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
            {/* end::Action */}

            <div className='text-gray-500 text-center fw-semibold fs-6 mt-4'>
              Didn't receive the OTP? <Button variant='link' className='text-primary'>Resend Code</Button>
            </div>
            <div className='text-gray-500 text-center fw-semibold fs-6 mt-5  d-flex align-items-center justify-content-center'>
              <Button
                className='link-primary'
                variant='link'
                onClick={() => {
                  SetOTPForm(false)
                }}
              >
                <i className='ki-duotone ki-black-left fs-2x text-primary'></i> Back
              </Button>
            </div>
          </form>
        </div>
      ) : (
        <div className='mobileNumber'>
          <form
            className='form w-100'
            onSubmit={Mobileformik.handleSubmit}
            noValidate
            id='kt_login_signin_form'
          >
            {/* begin::Heading */}
            <div className='text-center mb-11'>
              <Image
                src={toAbsoluteUrl('/media/logos/ilead-logo.png')}
                className='mb-5'
                style={{maxWidth: '180px'}}
              ></Image>
            </div>
            {/* begin::Heading */}

            {/* begin::Form group */}
            <div className='fv-row mb-8'>
              <label className='form-label fs-6 fw-bolder text-dark d-none d-md-block'>
                Mobile Number
              </label>
              <input
                placeholder='Mobile Number'
                {...Mobileformik.getFieldProps('mobile')}
                className={clsx(
                  'form-control bg-transparent rounded-pill',
                  {'is-invalid': Mobileformik.touched.mobile && Mobileformik.errors.mobile},
                  {
                    'is-valid': Mobileformik.touched.mobile && !Mobileformik.errors.mobile,
                  }
                )}
                type='mobile'
                name='mobile'
                autoComplete='off'
              />
              {Mobileformik.touched.mobile && Mobileformik.errors.mobile && (
                <div className='fv-plugins-message-container'>
                  <span role='alert'>{Mobileformik.errors.mobile}</span>
                </div>
              )}
            </div>
            {/* begin::Action */}
            <div className='d-grid mb-10'>
              <button
                type='submit'
                id='kt_sign_in_submit'
                className='btn btn-primary rounded-pill'
                disabled={Mobileformik.isSubmitting || !Mobileformik.isValid}
              >
                {!loading && <span className='indicator-label'>Send OTP</span>}
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Please wait...
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
            {/* end::Action */}
          </form>
        </div>
      )}
    </div>
  )
}
