import React, { useState, useEffect } from 'react'

const NoInternetConnection = (props: any) => {
    // state variable holds the state of the internet connection
    const [isOnline, setOnline] = useState(true)

    // On initization set the isOnline state.
    useEffect(() => {
        setOnline(navigator.onLine)
    }, [])

    // event listeners to update the state 
    window.addEventListener('online', () => {
        setOnline(true)
    });

    window.addEventListener('offline', () => {
        setOnline(false)
    });

    // if user is online, return the child component else return a custom component
    if (isOnline) {
        return (
            props.children
        )
    } else {
        return <div className='text-center'>
            <h1>⚠</h1>
            <h2>No connection to the internet</h2>
            <p>This Display has a connection to your network but no connection to the internet.</p>
            <p className="desc">The connection to the outside world is needed for updates and keeping the time.</p>
        </div>
    }
}

export default NoInternetConnection